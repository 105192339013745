<template id="optasetup">
  <b-container fluid>
    <h3>Principles Coach Setup</h3>
    <b-row>
      <b-col cols="4">
        <h5>Skills</h5>
        <b-row no-gutters>
          <b-col cols="1"> Id </b-col>
          <b-col cols="3"> Name </b-col>
          <b-col> Label </b-col>
        </b-row>
        <div style="max-height: 73vh; overflow-y: scroll">
          <draggable
            v-model="skill_ids"
            :group="{ name: 'skills', pull: 'clone', put: false }"
            :sort="false"
          >
            <div v-for="skill_id in skill_ids" :key="skill_id">
              <b-card
                no-body
                class="mb-1"
                :style="
                  'cursor: move;' +
                  (getIndexPrincipal(skill_id) > -1
                    ? 'color: #000000;background:' +
                      bg_opta[getIndexPrincipal(skill_id)]
                    : '')
                "
              >
                <b-row no-gutters align-v="center">
                  <b-col cols="1">
                    {{ getSkillById(skill_id).id }}
                  </b-col>
                  <b-col cols="3">
                    {{ getSkillById(skill_id).name }}
                  </b-col>
                  <b-col>
                    {{ getSkillById(skill_id).label }}
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </draggable>
        </div>
      </b-col>
      <b-col>
        <h5>Principles</h5>
        <div style="max-height: 75vh; overflow-y: scroll">
          <b-row no-gutters id="roles_content" ref="roles_content">
            <b-col
              cols="4"
              v-for="(principle, index) in principles_title"
              :key="'pri' + index"
              class="p-1"
            >
              <b-card
                :header="principle"
                no-body
                :style="'color:#000000;background:' + bg_opta[index]"
              >
                <b-card-body class="py-1">
                  <b-row>
                    <b-col class="border">
                      <draggable
                        :list="principles_skills[index]"
                        :group="{ name: 'skills', pull: true }"
                        @change="addSkillToPrinciple($event, index)"
                        style="min-height: 50px; margin-bottom: 10px"
                      >
                        <div
                          v-for="skill_id in principles_skills[index]"
                          :key="'sk' + skill_id"
                        >
                          <b-badge
                            v-if="getSkillIndexById(skill_id) > -1"
                            variant="dark"
                            :title="getSkillById(skill_id).label"
                            style="width: 250px"
                            class="py-1"
                          >
                            <b-row no-gutters>
                              <b-col>
                                <div
                                  style="
                                    cursor: move;
                                    font-size: 0.8rem;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 220px;
                                    text-align: left;
                                  "
                                >
                                  {{ getSkillById(skill_id).id }} -
                                  {{ getSkillById(skill_id).name }} -
                                  {{ getSkillById(skill_id).label }}
                                </div>
                              </b-col>
                              <b-col cols="1">
                                <b-icon
                                  icon="x"
                                  scale="2"
                                  style="cursor: pointer"
                                  @click="
                                    removeSkillFromPrinciple(index, skill_id)
                                  "
                                ></b-icon>
                              </b-col>
                            </b-row>
                          </b-badge>
                        </div>
                      </draggable>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <b-button
          size="sm"
          variant="primary"
          class="mt-2"
          @click="updatePrinciples"
          >Update principles</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import draggable from "vuedraggable";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  data: function () {
    return {
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "SET PLAY",
      ],
      bg_opta: [
        "#00ffff",
        "#da70d6",
        "#00ff00",
        "#808080",
        "#ffff00",
        "#1e90ff",
        "#f08080",
        "#2e8b57",
        "#FF8900",
        "#FF0059",
        "#a0522d",
        "#FFFFFF",
      ],
      principles_skills: [[], [], [], [], [], [], [], [], [], [], [], []],
      skills: [],
      skill_ids: [],
    };
  },

  components: {
    draggable,
  },

  created: function () {
    this.getSkills();
    //   this.getSkillsPrinciples();
  },

  methods: {
    getSkills() {
      this.$http.get("/opta/team/skills").then((response) => {
        this.skills = response.data;
        for (var i in this.skills) {
          this.skill_ids.push(this.skills[i].id);
        }
        this.getSkillsPrinciples();
      });
    },
    getSkillsPrinciples() {
      this.principles_skills = [[], [], [], [], [], [], [], [], [], [], [], []];
      this.$http.get("/opta/team/skills/principles").then((response) => {
        var principles = response.data;
        for (var i in principles) {
          var item = principles[i];
          this.principles_skills[item.principio].push(item.skill_id);
        }
      });
    },
    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },
    getSkillIndexById(id) {
      return this.skills.findIndex((skill) => skill.id == id);
    },
    addSkillToPrinciple: function (evt, index) {
      if (evt.added) {
        var skill_id = evt.added.element;
        if (!isNaN(skill_id)) {
          for (var i in this.principles_skills) {
            if (i != index) {
              var indexSkill = this.principles_skills[i].findIndex(
                (item) => item == skill_id
              );
              if (indexSkill > -1) {
                this.principles_skills[i].splice(indexSkill, 1);
              }
            }
          }
        }
      }
    },

    removeSkillFromPrinciple: function (index, skill_id) {
      var indexSkill = this.principles_skills[index].findIndex(
        (item) => item == skill_id
      );
      this.principles_skills[index].splice(indexSkill, 1);
    },

    getIndexPrincipal(skill_id) {
      var index = -1;
      for (var i in this.principles_skills) {
        if (this.principles_skills[i].includes(skill_id)) {
          return i;
        }
      }
      return index;
    },

    updatePrinciples() {
      this.$http
        .post("/opta/update/team/skills/principles", this.principles_skills)
        .then((response) => {
          this.getSkillsPrinciples();
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
        });
    },
  },

  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("MM/DD/YYYY");
      }
      return "";
    },
  },
};
</script>
